.project-card {
    width: 350px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(5, 77, 27, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.5s ease-in-out;
}

.project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(5, 77, 27, 0.5);
}

.project-card-image-container {
    width: 100%;
    height: 45%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
    justify-self: flex-start;
}

.project-card-image-container img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
}

.project-card h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 16px;
    padding: 0;
    color: rgb(4, 42, 8);
    font-family: 'Comfortaa', cursive;
    text-align: left;
}

.project-card p {
    font-size: 1rem;
    font-weight: 300;
    margin: 1em;
    padding: 0;
    color: rgba(1, 24, 15, 0.974);
    font-family: 'Comfortaa', cursive;
}
/* 
.project-card a:hover {
    color: rgb(22, 49, 167);
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1em;
} */

.project-card-links a {
    visibility: hidden;
    
}
.project-card-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    width: 350px;
    height: 400px;
    position: absolute;
    z-index: 5;
    border-radius: 20px;
    gap: 2em;
    visibility: none;
}

.project-card-links a {
    visibility: none;
    text-decoration: none;
    color: white;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1em;
    border-radius: 50px;
    width: 300px;
    height: 0px;
    overflow: hidden;
}

#github-logo {
    height: 40px;
}
.project-card:hover .project-card-links a {
    visibility: visible;
    text-decoration: none;
    color: white;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1em;
    border-radius: 50px;
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.5s ease-in-out;
    background-color: rgb(158, 154, 154);
}

.project-card-links a:active {
    transform: scale(0.9);
}

.project-card:hover .project-card-links a:hover {
    background-color: rgba(5, 77, 27, 0.5);
    transition: all 0.5s ease-in-out;
}

.project-card-links a:hover {
    font-size: 2em;
}

.project-card:hover .project-card-links {
    display: flex;
    backdrop-filter: blur(3px);
    background-color: rgba(212, 212, 212, 0.5);
    position: absolute;
    visibility: visible;
}

#project-tech-stack {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}