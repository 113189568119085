#landing-page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 100%;
    background-color: rgb(207, 215, 208);
}

.centered-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 50vh;
    gap: 3em;
    border-radius: 10px;
  }

.centered-content h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: white;
    text-align: center;
    font-family: 'Comfortaa', cursive;
    text-shadow: 0 0 10px rgba(5, 77, 27, 0.5);
  }

.centered-content h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
    color: white;
    text-align: center;

}

@media screen and (max-width: 1200px) {
    



}

#contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Comfortaa', cursive;
  width: 100%;
  margin-top: 2em;
  background-color: rgb(125, 135, 124);
  box-shadow: 0 0 10px rgb(35, 133, 64);
  z-index: 0;
  color: white;
}

#contact a {
  text-decoration: none;
}

#contact img {
  height: 50px;
}

#contact-icons {
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding: 2em;
}