@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

#scrolling-tech-stack {
    width: 100vw;
    height: 10vh;
    flex-wrap: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(100px);
}

.animate {
    animation: scroll 60s linear infinite;
    display: flex;
    flex-direction: row;
    width: 800vw;
    justify-content: space-around;
}

.tech-logo {
    height: 50px;
}


.tech-stack-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tech-stack-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200vw;
    margin-right: 3.5vw;
    margin-left: 3.5vw;
}

@media only screen and (max-width: 900px) {
    #scrolling-tech-stack {
        transform: translateY(0);
    }
    
}