#about-me-container {
    width: 100vw;
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
    flex-wrap: wrap;
    backdrop-filter: blur(100px);
    padding-bottom: 7vh;
    padding-top: 4vh;
}

#back-drop-container {
    background-color: rgb(238, 240, 243);
    background-image: url('../../../public/blob (2).svg');
    background-repeat: no-repeat;
    background-size: 1800px 30000px;
    background-position: center;
    box-shadow: 0 0 20px rgba(5, 77, 27, 0.5);
    height: 51vh;
}


#circle-profile-image {
    height: 75%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: block;
   margin-bottom: 2em;
}
#name-and-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    text-align: center;
    gap: 1em;

}

#name-and-title h3 {
    color: white;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    font-family: 'Comfortaa', cursive;
    font-weight: 300;
}

#name-and-title h1 {
    color: white;
    font-size: 5rem;
    margin: 0;
    padding: 0;
    z-index: 5;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    text-shadow: 0 0 20px rgba(5, 77, 27, 0.5);
}
/* 
#text-content-about-me {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    width: 70vw;
    height: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;

} */

@media only screen and (max-width: 600px)  {

    #about-me-container {
        height: 50vh;
        padding-bottom: 15vh;
    }

    #back-drop-container {
        height: 69vh;
    }

    #circle-profile-image {
        height: 50%;
    }
}



