#about-me-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: 60vh;
    margin: 5vh;
    background-color: rgb(125, 135, 124);
    box-shadow: 0 0 15px rgb(35, 133, 64);
}

#about-me-section-image {
    height: 400px;
    border-radius: 25px;
}

#about-me-section-text {
    width: 90%;
    text-align: center;
    font-family: 'Comfortaa', cursive;
    font-size: 1.1em;
    line-height: 2em;
    color: white;
    font-weight: 400;
}

#about-me-section-text b {
    font-size: 1.5em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 10px rgba(5, 77, 27, 0.5);
}

